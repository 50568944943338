import { Injectable } from '@angular/core';

import { CustomerService } from 'src/app/services/customer.service';
import { FormService } from 'src/app/services/form.service';
import { MsgboxService } from 'src/app/services/msgbox.service';
import { UserService } from 'src/app/services/user.service';
import { BackendApiService } from './backend-api.service';
import { SUPPORT_EMAIL } from '../util/helpers';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(
    private formService: FormService,
    private msgboxService: MsgboxService,
    private userService: UserService,
    private customerService: CustomerService,
    private backendApi: BackendApiService,
  ) {
  }

  async showFeedbackForm(interfaceId?: string) {
    const user = await this.userService.getUser();
    const customer = await this.customerService.getCustomerInfo(user.cid);

    const info = await this.formService.showForm('Give Feedback', [
      { name: 'feedback', label: 'Feedback', type: 'multiline', required: true, },
    ]);
    if (!info) return; // user cancelled
    const feedback = (info.feedback as string).trim();
    if (!feedback.replace(/[ \r\n\t]/g, '')) return; // ignore feedback that contains only blanks

    const body = {
      name: user.name || '',
      message: feedback,
      interfaceId: interfaceId || '',
      // to: 'olivier@jarvisml.com' // for tests
    };

    try {
      const response = await this.backendApi.post(`/feedback/add?cid=${customer.cid}`, body);
      if (response.status !== 'success')
        throw new Error('Feedback failed: ' + JSON.stringify(response));
      this.msgboxService.showMsg(`Thank you for giving us feedback!\nYour message has been recorded under the reference ${response.id}\n`);
    } catch (error) {
      console.error(error.message);
      this.msgboxService.showMsg(
        `Unfortunately there was a problem!\nPlease copy-paste the message below into an email for ${SUPPORT_EMAIL}\n\n` +
        `Feedback submit failure: ${error.message}\n${JSON.stringify(body)}`,
        { class: 'preText' }
      );
    }
  }

}
