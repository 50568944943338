import { Component, OnInit, Input } from '@angular/core';
import { RecoData, getRecoImage } from 'aidaptive-sdk';

@Component({
  selector: 'app-property-card',
  templateUrl: './property-card.component.html',
  styleUrls: ['./property-card.component.scss']
})
export class PropertyCardComponent implements OnInit {

  @Input() property: RecoData;
  sleep: string;
  bathrooms: string;
  bedrooms: string;
  public propertyImage: string;

  ngOnInit(): void {
    if (this.property && this.property.additional_fields) {
      const sleepsObject = this.findObjectWithSleeps(this.property.additional_fields);
      if (sleepsObject) {
        this.sleep = sleepsObject.value;
      }
    }
    this.bathrooms = String(this.property.bathrooms || this.property.hospitality_listing_detail?.bathrooms || '');
    this.bedrooms = String(this.property.bedrooms || this.property.hospitality_listing_detail?.bedrooms || '');

    this.propertyImage = getRecoImage(this.property);
  }
  
  findObjectWithSleeps(additionalFields) {
    if (additionalFields && additionalFields.length) {
      for (let i = 0; i < additionalFields.length; i++) {
        if ("sleeps" === additionalFields[i].key) {
          return additionalFields[i];
        }
      }
    }
    return null;
  }
}

