import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './home-page/home-page.component';
import { AdminAuthGuard, AuthGuard } from './user/auth.guard';
import { NotFoundComponent } from './not-found/not-found.component';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';

const routes: Routes = [
  { path: '', component: HomePageComponent },
  {
    path: 'login',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule)
  },
  {
    path: 'email-actions',
    loadChildren: () => import('./email-actions/email-actions.module').then(m => m.EmailActionsModule)
  },
  {
    path: 'shopify_install_accepted',
    loadChildren: () => import('./shopify/shopify.module').then(m => m.ShopifyModule),
  },
  {
    path: 'dev',
    loadChildren: () => import('./dev/dev.module').then(m => m.DevModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'integrations',
    loadChildren: () => import('./integrations/integrations.module').then(m => m.IntegrationsModule),
    canActivate: [AdminAuthGuard]
  },
  {
    path: 'metrics',
    loadChildren: () =>
      import('src/app/metrics-dashboard/metrics-dashboard.module').then(m => m.MetricsDashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'knowledge-graph',
    loadChildren: () =>
      import('src/app/knowledge-graph/entities.module').then(m => m.EntitiesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'merchandising',
    loadChildren: () =>
      import('src/app/merchandising/merchandising.module').then(m => m.MerchandisingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'audience-explorer',
    loadChildren: () =>
      import('src/app/audiences-explorer/audiences.module').then(m => m.AudiencesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('src/app/settings/settings.module').then(m => m.SettingsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AdminAuthGuard]
  },
  {
    path: 'sales-booster',
    loadChildren: () =>
      import('./upsell/upsell.module').then(m => m.UpsellModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'template-creator',
    loadChildren: () =>
      import('./template-creator/template-creator.module').then(m => m.TemplateCreatorModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'power-pricing',
    loadChildren: () =>
      import('./power-pricing/power-pricing.module').then(m => m.PowerPricingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'recommendations',
    loadChildren: () =>
      import('./recommendations/recommendations.module').then(m => m.RecommendationsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dynamic-experience',
    loadChildren: () =>
      import('./dynamic-experience/dynamic-experience.module').then(m => m.DynamicExperienceModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'spaces',
    loadChildren: () =>
      import('./spaces/spaces.module').then(m => m.SpacesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'aidaptive-events',
    loadChildren: () => import('./aidaptive-events/aidaptive-events.module').then(m => m.AidaptiveEventsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'watchtower',
    loadChildren: () => import('./watchtower/watchtower.module').then(m => m.WatchTowerModule),
    canActivate: [AdminAuthGuard]
  },
  {
    path: 'auto-experiments',
    loadChildren: () => import('./auto-experiment/auto-experiment.module').then(m => m.AutoExperimentModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'queue-processor',
    loadChildren: () => import('./queue-processor/queue-processor.module').then(m => m.QueueProcessorModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'documentation',
    loadChildren: () => import('./sdk-instructions/sdk-instructions.module').then(m => m.SdkInstructionsModule),
  },
  {
    path: 'welcome',
    loadChildren: () => import('./welcome/welcome.module').then(m => m.WelcomeModule),
  },
  {
    path: 'all-set',
    loadChildren: () => import('./setup-completed/setup-completed.module').then(m => m.SetupCompletedModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule),
  },
  { path: 'not-authorized', component: NotAuthorizedComponent },
  { path: '404', component: NotFoundComponent },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
