import { Component, OnInit, AfterViewInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';

export interface SelectorItem {
  display: string;
  value: any;
}

@Component({
  selector: 'generic-selector',
  templateUrl: './generic-selector.component.html',
  styleUrls: ['./generic-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericSelectorComponent implements OnInit, AfterViewInit {

  @Input() items: SelectorItem[];
  @Input() title: string;
  @Output() closeEvent = new EventEmitter<any>();

  @ViewChild(MatInput) filterBox: MatInput;

  filteredItems: SelectorItem[];

  ngOnInit(): void {
    // Replace undefined "display" fields - use value instead
    this.items = this.items.map(i => i.display !== undefined ? i : { display: `${i.value}`, value: i.value });
    
    this.filteredItems = this.items;
  }

  ngAfterViewInit() {
    setTimeout(() => this.filterBox.focus());
  }

  onKeyup(event: KeyboardEvent) {
    if (event.code === 'Enter') {
      if (this.filteredItems.length === 1) {
        this.closeEvent.emit(this.filteredItems[0].value);
      }
    } else if (event.code === 'Escape') {
      this.closeEvent.emit(null);
    }
  }

  onClickInside(event: PointerEvent) {
    // If we don't stop propagation here, dark div gets the click and closes the dialog
    event.stopPropagation();
  }
  onClickOutside() {
    this.closeEvent.emit(null);
  }

  onFilterChange(event) {
    const filter = event.target.value.toLowerCase();
    this.filteredItems = this.items.filter(item => {
      return item.display.toLowerCase().indexOf(filter) !== -1;
    });
  }

  onSelectionChange(event) {
    this.closeEvent.emit(event.option.id);
  }

}
