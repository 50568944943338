import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BackendDataService } from 'src/app/services/backend-data.service';
import { BackendApiService } from 'src/app/services/backend-api.service';
import { UserEntity } from 'src/app/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AidaptiveCustomerPageService {

  private minerUrl = environment.minerURL;

  constructor(
    private backendApi: BackendApiService,
    private backendDataService: BackendDataService
  ) { }


  public async getCustomerStatsData(cid: string) {
    return await this.backendApi.get(`/aidaptive_customers/get?cid=${cid}`,this.minerUrl);
  }

  public async getApiIntegrations(user: UserEntity) {
    return await this.backendApi.get(`/integration_configs/get/${user.cid}`, this.minerUrl);
  }

  public async getExternalIntegrationSchemma() {
    return await this.backendApi.get(`/integration_configs/get_external_integration_schema`, this.minerUrl);
  }

  public async getJourneyMosaicData(body: any) {
    return await this.backendApi.post(`/aidaptive_customer_mosaic/query`, body, this.minerUrl);
  }

  public async getCustomerJourneyProcess(user: UserEntity) {
    return await this.backendApi.get(`/aidaptive_customer_journey/process?cid=${user.cid}`, this.minerUrl);
  }

  public async getJourneyDataIntegration(user: UserEntity) {
    return await this.backendApi.get(`/aidaptive_customer_journey/get?cid=${user.cid}`, this.minerUrl);
  }

  public async getSearchAndRecoClick(user: UserEntity) {
    return await this.backendApi.get(`/metrics/journey?cid=${user.cid}`, this.minerUrl);
  }

  public async isDataIngestionComplete(user: UserEntity): Promise<boolean> {
    const data = await this.getJourneyDataIntegration(user);
    const statuses = data.data_integration_journey;
    if (statuses?.length) {
      for (const entityStatus of statuses) {
        if (entityStatus.status === 'complete') return true; // only one of them "complete" is enough
      }
    }
    return false;
  }

  public async postApiIntegrations(user: UserEntity, body: any) {
    return await this.backendApi.post(`/integration_configs/add_api_info/${user.cid}`, body, this.minerUrl);
  }

  public async addIntegrationConfigs(body: any) {
    return await this.backendApi.post(`/integration_configs/add`, body, this.minerUrl);
  }

  public async getAidaptiveCapabilitiesInfo(title: string,cid: string) {
    return await this.backendApi.post(`/comms/learn_more?tile_header=${title}&cid=${cid}`, {}, this.minerUrl);
  }

  public async fetchSummaryData(config) {
    return await this.backendDataService.fetchJsonData(config.url);      
  }

  public async isJourneyReady(cid: string) {
    const resp = await this.getJourneyMosaicData({ cid: cid, max_tiles_per_row: 7 });
    if (!resp?.rows?.length || !resp?.sections?.length) return false;
    const sections = resp.sections;
    const rows = sections[0]?.rows;
    if (!rows) return false;
    const tiles = rows[0]?.tiles;
    if (!tiles) return false;

    const headersToCheck = new Set(["Products", "Customers", "Purchases", "Hospitality listings", "Hospitality customers", "Hospitality reservations"]);
    for (const tile of tiles) {
      if (!headersToCheck.has(tile.header)) continue;
      if (tile.state === "ready") { // are other states interesting? e.g. || tile.state === "pending") {
        return true;
      }
    }
    return false;
  }

  public async getInternalCustomerSettings(cid: string) {
    return await this.backendApi.get(`/aidaptive_customer_settings/get?cid=${cid}`,this.minerUrl);
  }

  public async updateInternalCustomerSettings(cid: string, body: any) {
    return await this.backendApi.post(`/aidaptive_customer_settings/update?cid=${cid}`, body, this.minerUrl);
  }
}