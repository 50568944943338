import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentData } from '@angular/fire/compat/firestore';
import { UserService } from './user.service';
import { getAllDocs, getOneDoc } from '../util/helpers';
import { environment } from 'src/environments/environment';
import { BackendApiService } from './backend-api.service';
import { UserEntity } from '../models/user.model';
import { AidaptiveCustomerSetting } from '../models/aidaptive-customer-settings.model';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  private minerUrl = environment.minerURL;

  constructor(
    private firestore: AngularFirestore,
    private userService: UserService,
    private backendApi:BackendApiService
  ) { }

  async getAllCustomers(user: UserEntity): Promise<AidaptiveCustomerSetting[]> {
    if (user.jarvisml_group) {
      const customers = await this.backendApi.get<AidaptiveCustomerSetting[]>("/aidaptive_customer_settings/list", this.minerUrl);
      return customers;
    } else if (user.cid) {
      const customer = await this.backendApi.get<AidaptiveCustomerSetting>(`/aidaptive_customer_settings/get?cid=${user.cid}`, this.minerUrl);
      return [customer];
    } else {
      return [];
    }
  }

  public async getCustomerInfo(cid: string): Promise<AidaptiveCustomerSetting> {
    return this.backendApi.get(`/aidaptive_customer_settings/get?cid=${cid}`,this.minerUrl);
  }

  async getAllCampaigns(cid: string) {
    const collection = this.firestore.collection<any>(`Customers/${cid}/SalesBooster`);
    return getAllDocs(collection);
  }

  async getCampaignDetails(cid: string, campaignId: string) {
    const doc = this.firestore.doc<any>(`Customers/${cid}/SalesBooster/${campaignId}`);
    const snapshot = await doc.get().toPromise();
    return { ...snapshot.data()};
  }

  async getIntegrationSetup(cid: string, provider: string): Promise<DocumentData> {
    return getOneDoc(this.firestore.doc(`/Customers/${cid}/Integrations/${provider}`));
  }

  async setupIntegration(cid: string, provider: string, data: any): Promise<void> {
    const doc = this.firestore.doc(`/Customers/${cid}/Integrations/${provider}`);
    return doc.set(data, { merge: true });
  }

  async savePinnedConfig(cid: string, graphConfig: any): Promise<void> {
    const doc = this.firestore.doc(`/Customers/${cid}/Settings/dashboard`);
    const configData = {};
    configData[graphConfig.id] = {
      pinned: true,
      pinnedConfig: graphConfig.pinnedConfig
    };
    return doc.set(configData, { merge: true });
  }

  async removePin(cid: string, graphConfig: any): Promise<void> {
    const doc = this.firestore.doc(`/Customers/${cid}/Settings/dashboard`);
    const configData = {};
    configData[graphConfig.id] = {
      pinned: false,
    };
    return doc.set(configData, { merge: true });
  }

  async getGraphConfigs(cid: string) {
    const doc = this.firestore.doc<any>(`Customers/${cid}/Settings/dashboard`);
    const snapshot = await doc.get().toPromise();
    return { ...snapshot.data()};
  }

}
