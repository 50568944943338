import { Component, OnInit, Input } from '@angular/core';

import { UserEntity } from 'src/app/models/user.model';
import { MyGraphConfig } from 'src/app/shared/mygraph/mygraph.component';
import { CustomerSettingService } from 'src/app/services/customer-setting.service';
import { CustomerService } from 'src/app/services/customer.service';
import { UserService } from 'src/app/services/user.service';
import { GraphConfig } from 'src/app/shared/generic-graph/generic-graph.component';
import { GraphSettings } from 'src/app/util/GraphSettings';
import { GraphPickerService } from 'src/app/services/graph-picker.service';
import { MsgboxService } from 'src/app/services/msgbox.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { PageService } from 'src/app/services/page.service';



export interface GraphPickerConfig {
  data?: unknown; // can be used to store custom data
  choices?: string[]; // if not provided, choices should be returned by init method below
  init?: (cfg: GraphConfig) => Promise<string[]>;
  buttonLabel: (cfg: GraphConfig) => string;
  makeNewConfig: (cfg: GraphConfig, choice: string) => void;
  onSelect: (cfg: GraphConfig, choiceIndex: number) => Promise<void>;
}

@Component({
  selector: 'graph-picker',
  templateUrl: './graph-picker.component.html',
  styleUrls: ['./graph-picker.component.scss']
})
export class GraphPickerComponent implements OnInit {

  @Input() parentConfig: GraphConfig;

  pickerConfig: GraphPickerConfig;
  public configs: GraphConfig[] = [];
  public user: UserEntity;
  title: string;
  subtitle: string;

  constructor(
    private customerService: CustomerService,
    private customerSettingService: CustomerSettingService,
    private userService: UserService,
    private msgboxService: MsgboxService,
    private spinnerService: SpinnerService,
    private graphPickerService: GraphPickerService,
    public pageService: PageService,
  ) {
  }

  async ngOnInit() {
    this.pickerConfig = this.parentConfig.graphPicker;

    const user = this.user = await this.userService.getUser();
    const customer = await this.customerService.getCustomerInfo(user.cid);
    
    this.title = `Graph Picker for "${this.parentConfig.title}"`;
    this.subtitle = `Select the graph you want below. It will be displayed for ALL users in ${customer.aidaptive_customer_name}.`;

    setTimeout(() => this.initAllGraphs());
  }

  async initAllGraphs() {
    this.spinnerService.addSpinner('Initializing graph picker...');

    if (this.pickerConfig) {
      this.pickerConfig.choices = await this.pickerConfig.init(this.parentConfig);

      this.pickerConfig.choices.forEach(choice => {
        this.configs.push(this.newGraph(choice));
      });
    }

    this.spinnerService.removeSpinner();
  }

  private newGraph(choice: string): GraphConfig {
    const newConfig = Object.assign({}, this.parentConfig);
    if (newConfig.type === 'mygraph') {
      const cfg = newConfig as MyGraphConfig;
      if (cfg.multicurve) {
        cfg.multicurve = cfg.multicurve.concat();
        for (let i = 0; i < cfg.multicurve.length; i++) {
          cfg.multicurve[i] = Object.assign({}, cfg.multicurve[i]);
        }
      }
    }

    newConfig.id = `${newConfig.id}-${choice}`;
    newConfig.title = choice;
    newConfig.subtitle = '';
    newConfig.hideHelpButton = true;

    newConfig.settings = this.createSettings(newConfig);

    this.pickerConfig.makeNewConfig(newConfig, choice);

    return newConfig;
  }

  // For a "live" graph, settings are a doing a lot more things,
  // but here we only need them so that the graph appears well in the picker.
  private createSettings(cfg: GraphConfig) {
    return new GraphSettings(
      cfg,
      {}, { hidden: false },
      this.user, this.customerSettingService, null,
      null
    );
  }

  async onSelect(choiceIndex: number) {
    if (choiceIndex === -1) {
      this.graphPickerService.hidePicker();
      return;
    }

    const choice = await this.msgboxService.showQuestion(
      `Choose "${this.pickerConfig.choices[choiceIndex]}" ?`,
      ['Cancel', 'Yes']
    );
    if (choice === 0) return;

    const success = await this.spinnerService.doWithSpinner('Saving graph choice...', async () => {
      await this.pickerConfig.onSelect(this.parentConfig, choiceIndex);
      return true;
    });
    if (success) {
      this.graphPickerService.hidePicker();
      this.msgboxService.showMsg('Please reload the page to see the chosen graph updated');
    }
  }
}
