import { Component, OnInit, Input } from '@angular/core';

import { BackendDataService } from 'src/app/services/backend-data.service';
import { GenericGraphComponent, GraphConfig, InputParams } from '../generic-graph/generic-graph.component';
import { formatDateForBackend } from 'src/app/util/helpers';

export interface ImageGraphConfig extends GraphConfig {
  type: 'image';
}

@Component({
  selector: 'image-graph',
  templateUrl: './image-graph.component.html',
  styleUrls: ['./image-graph.component.scss']
})
export class ImageGraphComponent implements OnInit {

  @Input() config: ImageGraphConfig;
  @Input() parent: GenericGraphComponent;
  @Input() inputParams: InputParams = {};

  public loading: boolean;
  public imageData: string;

  constructor(
    private backendDataService: BackendDataService
  ) { }

  async ngOnInit() {
    this.loading = true;

    setTimeout(() => this.fetchImageData(), 300);
  }

  private async fetchImageData(): Promise<void> {
    this.loading = true;
    this.imageData = null;
    this.parent.showLoading();

    try {
      const startDateParam = this.inputParams.startDate ? `&start_date=${formatDateForBackend(this.inputParams.startDate)}` : '';

      let endDateParam = '';
      if (this.inputParams.endDate) {
        const endDateStr = formatDateForBackend(this.inputParams.endDate);
        const currentDateStr = formatDateForBackend(new Date());
        // Backend API doesn't accept current date as end date
        if (endDateStr !== currentDateStr) {
          endDateParam = `&end_date=${endDateStr}`;
        }
      }

      let imageData;
      if (this.config.url) {
        const imgUrl = `${this.config.url}${startDateParam}${endDateParam}`;
        imageData = await this.backendDataService.fetchImageData(imgUrl);
      } else {
        console.error(`Empty url for ${this.config.title}`);
        imageData = '';
      }
  
      if (imageData.length > 0) {
        this.imageData = imageData;
        this.parent.showData();
      } else {
        this.parent.showNoData();
      }
    } catch (error) {
      this.parent.showFailure(error);
    }
    this.loading = false;
  }

}
