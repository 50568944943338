import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, delay, retryWhen, scan } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';

import { environment } from 'src/environments/environment'; // add ".local" for testing
import { UserService } from './user.service';

export type Verb = 'get' | 'post' | 'patch' | 'put' | 'delete';
export type ReqBody = { [s: string]: any };

type UntypedResponse = any; // TODO: type all responses and remove this default

@Injectable({
  providedIn: 'root'
})
export class BackendApiService {

  constructor(
    private userService: UserService,
    private http: HttpClient
  ) {
  }

  buildServerUrl(apiUrl: string): string {
    return `${environment.backendAPI}${apiUrl}`;
  }

  private buildUrl(apiUrl: string, host?: string): string {
    if (host) {
      return `${host}${apiUrl}`;
    } else {
      return `${environment.backendAPI}${apiUrl}`;
    }
  }

  async getWithCredentials(apiUrl: string, host?: string) {
    return JSON.parse(await this.fetchRawData(this.buildUrl(apiUrl, host), { withCredentials: true }));
  }
  async getWithoutCredentials(apiUrl: string, host?: string) {
    return JSON.parse(await this.fetchRawData(this.buildUrl(apiUrl, host), { withCredentials: false }));
  }

  async getWithUserToken(apiUrl: string, host?: string) {
    return JSON.parse(await this.fetchRawData(this.buildUrl(apiUrl, host), { withUserToken: true }));
  }

  private async fetchRawData(fullUrl: string, options: { withUserToken?: boolean, withCredentials?: boolean }): Promise<string> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json', // not always true but seems to work
    });
    if (options.withUserToken) {
      const accessToken = await this.userService.getIdToken();

      headers = headers.append('Authorization', `Bearer ${accessToken}`);
    }

    return new Promise((resolve, reject) => {
      const observable = this.http.get(fullUrl, {
        headers,
        withCredentials: options.withCredentials || false,
        responseType: "arraybuffer"
      })
        .pipe(
          this.handleRetry,
          catchError(e => {
            reject(new Error(this.getErrorMessage(e)));
            return EMPTY;
          })
        );
  
      observable.subscribe((response: ArrayBuffer) => {
        // NB: String.fromCharCode.apply(null, new Uint8Array(response)) is shorter
        // but cause the stack to blow because of parameter size. See for example https://stackoverflow.com/questions/38432611/converting-arraybuffer-to-string-maximum-call-stack-size-exceeded
        let binaryData = '';
        const bytes = new Uint8Array(response);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
          binaryData += String.fromCharCode(bytes[i]);
        }
        resolve(binaryData);
      });  
    });
  }

  private callHttpVerb(verb: Verb, token: string, fullUrl: string, body?: ReqBody): Observable<unknown> {
    const options = { headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` }) };
    switch (verb) {
      case 'post': case 'put': case 'patch':
        return this.http[verb](fullUrl, body, options);
      case 'get':
        return this.http[verb](fullUrl, options);
      case 'delete':
        return this.http[verb](fullUrl, options);
      default:
        throw new Error(`Bad verb: ${verb}`);
    }
  }

  private async doVerb<T>(verb: Verb, fullUrl: string, body?: ReqBody): Promise<T> {
    const accessToken = await this.userService.getIdToken();

    return new Promise((resolve, reject) => {
      const observable = this.callHttpVerb(verb, accessToken, fullUrl, body)
        .pipe(
          this.handleRetry,
          catchError(e => {
            reject(new Error(this.getErrorMessage(e)));
            return EMPTY;
          })
        );
  
      observable.subscribe((response) => {
        resolve(response as T);
      });
    });
  }

  public async doGeneric<T>(verb: Verb, apiUrl: string, host: string, body?: ReqBody): Promise<T> {
    switch (verb) {
      case 'get': case 'delete':
        if (body) {
          console.error(`body ignored for ${verb}`, body);
        }
        return this.doVerb(verb, this.buildUrl(apiUrl, host));
      case 'post': case 'put': case 'patch':
        if (!body) {
          console.error(`body missing for ${verb}`);
          body = {};
        }
        return this.doVerb(verb, this.buildUrl(apiUrl, host), body);
      default:
        throw new Error(`Bad verb: ${verb}`);
    }
  }

  async get<T=UntypedResponse>(apiUrl: string, host?: string): Promise<T> {
    return this.doVerb('get', this.buildUrl(apiUrl, host)); // equivalent to getWithUserToken
  }

  async post<T=UntypedResponse>(apiUrl: string, body: ReqBody, host?: string): Promise<T> {
    return this.doVerb('post', this.buildUrl(apiUrl, host), body);
  }

  async delete<T=UntypedResponse>(apiUrl: string, host?: string): Promise<T> {
    // NB: we don't pass a body for DELETE verb since we tried and got into troubles.
    // See for example there: https://stackoverflow.com/questions/299628/is-an-entity-body-allowed-for-an-http-delete-request?rq=1
    return this.doVerb('delete', this.buildUrl(apiUrl, host));
  }

  async patch<T=UntypedResponse>(apiUrl: string, body: ReqBody, host?: string): Promise<T> {
    return this.doVerb('patch', this.buildUrl(apiUrl, host), body);
  }

  async put<T=UntypedResponse>(apiUrl: string, body: ReqBody, host?: string): Promise<T> {
    return this.doVerb('put', this.buildUrl(apiUrl, host), body);
  }

  private handleRetry<T>(source: Observable<T>): Observable<T> {
    return source.pipe(retryWhen(e => e.pipe(scan((errorCount, error) => {
      // Do not retry unless 5xx
      if (String(error.status)[0] !== '5') {
        throw error;
      }
      if (errorCount >= 3) {
        throw error;
      }
      return errorCount + 1;
    }, 1),
    delay(1000)
    )));
  }

  private getErrorMessage(error: HttpErrorResponse): string {
    let errorMessage = 'Unknown error!';

    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else if (error.error instanceof ProgressEvent) {
      // Client also gets ProgressEvent in error sometimes (no useful info in this)
      errorMessage = error.message;
    } else {
      // --- Server-side errors
      if (error.error?.status) {
        // not 100% sure about this, but this is where our backend status is "sometimes"...
        errorMessage = error.error.status;
      } else if (error.error?.detail) {
        if (typeof error.error.detail === 'string') {
          errorMessage = error.error.detail;
        } else {
          errorMessage = JSON.stringify(error.error.detail);
        }
      } else if (String(error.status)[0] === '5') {
        errorMessage = `Unexpected server error (${error.status}). \n` +
          `Try to refresh this page, and feel free to contact us if the problem persists.`;
      } else {
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        if (error.error) {
          errorMessage += `\n\n${JSON.stringify(error.error)}`;
        }
      }  
    }
    console.error(errorMessage, error);
    return errorMessage;
  }

}
