import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AidaptiveCustomerSetting } from '../models/aidaptive-customer-settings.model';
import { BackendApiService } from './backend-api.service';

interface UserLinkConfig {
  userId: string;
  integration?: string;
  cid?: string;
}

type ExternalSystemSchemaResponse = {
  vertical: string;
  external_systems: { value: string; display: string; }[];
}[];

@Injectable({
  providedIn: 'root'
})
export class AidaptiveCustomerSettingService {
  private minerUrl = environment.minerURL;
  private uatuUrl = environment.uatuAPI;

  constructor(
    private backendApi: BackendApiService,
  ) { }

  public async addAidaptiveCustomerSettings(aidaptiveCustomerSetting: AidaptiveCustomerSetting) {
    return await this.backendApi.post(`/aidaptive_customer_settings/add`, aidaptiveCustomerSetting, this.minerUrl);
  }

  public async getAllAidaptiveCustomerSettings(): Promise<any[]> {
    return await this.backendApi.get(`/aidaptive_customer_settings/list`, this.minerUrl);
  }

  public async getSnapshotListByCid(cid: string): Promise<any[]> {
    return await this.backendApi.get(`/aidaptive_customer_settings/list_snapshots?cid=${cid}`, this.minerUrl);
  }

  public async getStreamValidationResults(cid: string, watcherType: string, status: string , numOfRecords: string): Promise<any[]> {
    return await this.backendApi.get(`/watcher/get_stream_validation_results?cid=${cid}&watcher_type=${watcherType}&status=${status}&num_records=${numOfRecords}`, this.minerUrl);
  }

  public async getDatasetWatcherConfigs(): Promise<any[]> {
    return await this.backendApi.get(`/dataset_watcher_configs/get`, this.uatuUrl);
  }

  public async getConfigDataByName(configName:string, limit =50): Promise<any[]> {
    return await this.backendApi.get(`/dataset_watcher_runs/extension/get_by_config_name_grouped_by_key?config_name=${configName}&skip=0&limit=${limit}`, this.uatuUrl);
  }

  public async getConfigByName(configName:string): Promise<any[]> {
    return await this.backendApi.get(`/dataset_watcher_configs/get_by_name?config_name=${configName}`, this.uatuUrl);
  }
  public async updateAidaptiveCustomerStatus(cid: string, status: string) {
    return await this.backendApi.get(`/aidaptive_customer_settings/update_status?cid=${cid}&status=${status}`, this.minerUrl);
  }

  public async updateAidaptiveCustomerEnhancerStatus(cid: string, enhancerStatus: boolean) {
    return await this.backendApi.get(`/aidaptive_customer_settings/update_enhancer_enabled?cid=${cid}&enhancer_enabled=${enhancerStatus}`, this.minerUrl);
  }

  public async updateAidaptiveCustomerRecsStatus(cid: string, recsStatus: boolean) {
    return await this.backendApi.get(`/aidaptive_customer_settings/update_recs_enabled?cid=${cid}&recs_enabled=${recsStatus}`, this.minerUrl);
  }

  public async updateAidaptiveCustomerSearchStatus(cid: string, searchStatus: boolean) {
    return await this.backendApi.get(`/aidaptive_customer_settings/update_search_enabled?cid=${cid}&search_enabled=${searchStatus}`, this.minerUrl);
  }

  public async updateAidaptiveCustomerMerchStatus(cid: string, merchStatus: boolean) {
    return await this.backendApi.get(`/aidaptive_customer_settings/update_merchandising_enabled?cid=${cid}&merchandising_enabled=${merchStatus}`, this.minerUrl);
  }

  public async updateAidaptiveCustomerPayingStatus(cid: string, payingStatus: boolean) {
    return await this.backendApi.get(`/aidaptive_customer_settings/update_paying_customer?cid=${cid}&is_paying=${payingStatus}`, this.minerUrl);
  }

  public async updateAidaptiveCustomerImageModellingStatus(cid: string, imageModellingStatus: boolean) {
    return await this.backendApi.get(`/aidaptive_customer_settings/update_image_modeling_enabled?cid=${cid}&image_modeling_enabled=${imageModellingStatus}`, this.minerUrl);
  }

  public async updateAidaptiveCustomerSettings(body: any) {
    return await this.backendApi.post(`/aidaptive_customer_settings/update`,body ,this.minerUrl);
  }

  public async getAidaptiveCustomerSettings(cid: string) {
    return this.backendApi.get('/aidaptive_customer_settings/get?cid=' + cid, this.minerUrl);
  }

  public async getTrafficAllocationData(cid: string) {
    return this.backendApi.get('/experiments/traffic_allocation?cid=' + cid, this.minerUrl);
  }

  public async getAidaptiveCustomerDetails(cid: string) {
    return this.backendApi.get('/aidaptive_customers/get?cid=' + cid, this.minerUrl);
  }

  public async getMetricsHistogramData(cid: string, service: string) {
    return this.backendApi.get(`/metrics/histogram?cid=${cid}&service=${service}`, this.minerUrl);
  }

  public getExternalSystemSchema() {
    return this.backendApi.get<ExternalSystemSchemaResponse>('/aidaptive_customer_settings/get_external_system_schema', this.minerUrl);
  }

  public async userLink(params: UserLinkConfig) {
    let apiUrl = `/user/link?uid=${params.userId}`;
    if (params.integration) {
      apiUrl += `&integration_name=${params.integration}`;
    }
    if (params.cid) {
      apiUrl += `&cid=${params.cid}`;
    }
    return await this.backendApi.post(apiUrl, {}, this.minerUrl);
  }
}
