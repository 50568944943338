import { Component, OnInit } from '@angular/core';
import { UserEntity } from 'src/app/models/user.model';
import { CustomerSettingService } from 'src/app/services/customer-setting.service';
import { UserService } from 'src/app/services/user.service';

export type TabName = 'audiences' | 'metrics' | 'recs' | 'powerPricing' |
  'searchandising' | 'insights' |
  'support' | 'settings' | 'admin' | 'knowledgeGraph';

export interface TabPrefsEntity {
  audiences: TabSettings;
  metrics: TabSettings;
  recs: TabSettings;
  powerPricing: TabSettings;
  searchandising: TabSettings;
  insights: TabSettings;
  support: TabSettings;
  settings: TabSettings;
  admin: TabSettings;
  knowledgeGraph: TabSettings;
}

export const DEFAULT_TABS: TabPrefsEntity = {
  audiences: { showInternally: true, showExternally: false },
  knowledgeGraph: { showInternally: true, showExternally: true },
  metrics: { showInternally: true, showExternally: true },
  recs: { showInternally: true, showExternally: true },
  powerPricing: { showInternally: true, showExternally: false },
  searchandising: { showInternally: false, showExternally: false },
  insights: { showInternally: true, showExternally: false },
  support: { showInternally: true, showExternally: true, disabled: true },
  settings: { showInternally: true, showExternally: true, disabled: true },
  admin: { showInternally: true, showExternally: false, disabled: true },
};

interface TabSettings {
  showInternally: boolean;
  showExternally: boolean;
  disabled?: boolean
}

@Component({
  selector: 'app-tab-preferences',
  templateUrl: './tab-preferences.component.html',
  styleUrls: ['./tab-preferences.component.scss']
})

export class TabPrefrencesComponent implements OnInit {

  data: TabPrefsEntity;
  user: UserEntity;
  noSettingsAvailable: boolean;

  constructor(
    private userService: UserService,
    private customerSettings: CustomerSettingService
  ) { }

  async ngOnInit(): Promise<void> {
    this.user = await this.userService.getUser();
    this.data = await this.customerSettings.getTabPreferences(this.user.cid);
  }

  async toggleVisibility(): Promise<void> {
    await this.customerSettings.modifyTabPreferences(this.user.cid, this.data);
    window.location.reload();
  }
}