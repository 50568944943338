<div class="darkDiv">

  <div class="form-frame" (keyup)="onKeyup($event)">
    <div class="form-title">{{title}}</div>

    <div *ngIf="isFilterActive()" class="searchComponent">
      <mat-form-field class="form-field" appearance="outline">
        <mat-label> Search </mat-label>
        <input type="text" matInput [(ngModel)]="enteredSearchVal" #focus />    
      </mat-form-field>
    </div>

    <div class="form-container">
      <form [formGroup]="editForm">

        <div [ngClass]="{'fieldsDivWithScrollbar':fields.length > 7, 'fieldsDiv': fields.length <= 7 }" >
          <ng-container *ngFor="let f of fields; trackBy:trackField">
            <ng-container *ngIf="isFilteredIn(f)">
              <div *ngIf="f.type=='label'" [ngClass]="f.classNames" class="label">{{f.label}}</div>

              <div *ngIf="f.type=='boolean'" class="checkbox">
                <input [id]="'checkbox-' + f.name" type="checkbox" [formControlName]="f.name" class="checkboxBox" #focus>
                <label [for]="'checkbox-' + f.name" class="checkboxLabel">{{f.label}}</label>
              </div>

              <ng-container *ngIf="f.type=='list'">
                <mat-label> {{f.label}}: </mat-label>
                <mat-selection-list [formControlName]="f.name" #focus>
                  <div *ngIf="!f.selectList.length" class="placeholder">(none)</div>
                  <mat-list-option *ngFor="let item of f.selectList; trackBy:trackSelectListItem" [value]="item">{{item.viewValue}}</mat-list-option>
                </mat-selection-list>
              </ng-container>

              <mat-form-field *ngIf="f.type=='string'||f.type=='integer'||f.type=='multiline'||f.type=='select'||f.type=='date' || f.type=='multiselect'"
                class="form-field" appearance="outline">

                <mat-label> {{f.label}} </mat-label>

                <ng-container *ngIf="f.autoComplete">
                  <input matInput [name]="f.name+'-in-form'" [formControlName]="f.name"
                    [pattern]="f.regexp" [matAutocomplete]="auto"
                    (input)="onAutoCompleteChange(f)" (focus)="$event.target.select()"
                    #focus
                  >
                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <mat-option *ngFor="let option of f.data||f.autoComplete" [value]="option">{{option}}</mat-option>
                  </mat-autocomplete>  
                </ng-container>
                
                <input *ngIf="f.type=='string' && !f.autoComplete" matInput [name]="f.name+'-in-form'" [formControlName]="f.name" [pattern]="f.regexp" autocomplete="off" #focus>
                <input *ngIf="f.type=='integer'" matInput [name]="f.name+'-in-form'" [formControlName]="f.name" [pattern]="f.regexp" autocomplete="off" #focus>
                <textarea *ngIf="f.type=='multiline'" matInput rows="3" [formControlName]="f.name" [pattern]="f.regexp" #focus></textarea>
                <mat-select *ngIf="f.type=='select'" [formControlName]="f.name" #focus>
                  <mat-option *ngFor="let option of f.selectList; trackBy:trackSelectListItem" [value]="option.value">{{option.viewValue}}</mat-option>
                </mat-select>
                  <mat-select *ngIf="f.type=='multiselect'" [formControlName]="f.name" multiple #focus>
                    <mat-option *ngFor="let option of f.selectList; trackBy:trackSelectListItem" [value]="option.value">
                      {{option.viewValue}}
                    </mat-option>
                </mat-select>

                <input *ngIf="f.type=='date'" matInput [name]="f.name+'-in-form'" [formControlName]="f.name" [matDatepicker]="getDatePicker(f.name)">
                <mat-datepicker-toggle *ngIf="f.type=='date'" matSuffix [for]="getDatePicker(f.name)"></mat-datepicker-toggle>
                <mat-datepicker *ngIf="f.type=='date'" #datePicker [id]="f.name"></mat-datepicker>
        
              </mat-form-field>

              <button *ngIf="f.type=='button'" [class]="f.class||'modestButton'" type="button" #focus
                (click)="buttonClick(f)">
                {{f.label}}
              </button>

            </ng-container>
          </ng-container>
        </div>

        <div class="flex justify-end">
          <div class="mx-2">
            <button class="text-gray-500 bg-white hover:bg-red-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-md border border-gray-300 w-24 text-sm font-medium px-5 py-2.5 focus:z-10 " type="button" (click)=cancelForm()>Cancel</button>
          </div>
          <div>
            <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm px-5 w-24 py-2.5 text-center ml-2" type="button" #focus (click)="submit('OK')">OK</button>
          </div>
        </div>
        
      </form>
    </div>
  </div>  

</div>
  