import { Component, OnInit, Input } from '@angular/core';
import { BackendDataService } from 'src/app/services/backend-data.service';

@Component({
  selector: 'app-jarvis-grid-image-graph',
  templateUrl: './jarvis-grid-image-graph.component.html',
  styleUrls: ['./jarvis-grid-image-graph.component.scss']
})
export class JarvisGridViewComponent implements OnInit {

  public rawData: any[];
  public footerText: string;
  public loading: boolean;
  filterConfig;
  errorFetchingData = false;

  constructor(
    private backendDataService: BackendDataService
  ) {

  }

  ngOnInit(): void {
    this.fetchSummaryData(this.config);
  }

  @Input() config;
  @Input() parent;
  @Input() inputParams;


  private async fetchSummaryData(config): Promise<void> {
    this.loading = true;
    try {
      const graphUrl = config?.url;

      // TODO: pass inputParams thru instead of filterConfig
      const filterConfig = {};

      this.filterConfig = filterConfig;
      // Some graph urls need customization before a request is made

      if (graphUrl) {
        this.rawData = await this.backendDataService.fetchJsonData(graphUrl);
      } else {
        console.error(`Empty url for ${config.title}`);
        this.rawData = [];
      }
      let filteredData: any[];
      filteredData = this.rawData;

      if (config.responseHandler) {
        const responseData = config.responseHandler(filteredData, this);
        if (responseData && responseData.length) {
          filteredData = responseData;
        }
      }
      if (filteredData.length > 0) {
        this.loading = false;
      }
      this.errorFetchingData = false;

    } catch (error) {
      console.log(error);
      this.errorFetchingData = true;
    }
    this.loading = false;
  }
}
