import { Component, OnInit, Input, SecurityContext, OnChanges } from '@angular/core';
import { EMPTY } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment'; // add ".local" for testing
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'file-detail',
  templateUrl: './file-detail.component.html',
  styleUrls: ['./file-detail.component.scss']
})
export class FileDetailComponent implements OnInit, OnChanges {

  @Input() filepath: string;
  htmlToAdd: string;

  constructor(
    private sanitizer: DomSanitizer,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    // TODO: display a pretty loading UI insted of this
    this.htmlToAdd = "Retrieving details for: " + this.filepath + "...";
    // TODO: if file is an image, show that instead
    this.retrieveDetailsHtml();
  }

  retrieveDetailsHtml(): void {
    const headers = new HttpHeaders({
      'Content-Type': 'text/html'
    });
    const url = environment.insightsURL + "/insights/get_stats?path=" + this.filepath;

    const observable = this.http.get(url, { headers: headers, responseType: "arraybuffer" })
      .pipe(retry(3), catchError((e) => this.handleError(e)));

    // NB: assets/facets-jupyter.html is from https://raw.githubusercontent.com/PAIR-code/facets/master/facets-dist/facets-jupyter.html
    observable.subscribe((data: ArrayBuffer) => {
      this.htmlToAdd = this.sanitizer.sanitize(SecurityContext.HTML,
        this.sanitizer.bypassSecurityTrustHtml(
          '<iframe width="100%" height="100%" srcdoc=\''+
        '<script src="https://cdnjs.cloudflare.com/ajax/libs/webcomponentsjs/1.3.3/webcomponents-lite.js"></script>' +
        '<link rel="import" href="/assets/facets-jupyter.html">' +

        '<facets-overview proto-input="' +
          String.fromCharCode.apply(null, new Uint8Array(data)) +
        '"></facets-overview>' +
        '\'></iframe>'));
    });
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    this.htmlToAdd = errorMessage; // TODO: professional error message + logging/alert for us
    return EMPTY;
  }
}
