export interface AidaptiveCustomerSetting {
  breakdown?: any;
  cid: string;
  aidaptive_customer_name?: string;
  billing_strategy?: string;
  store?: {
    base_url?: string;
  };
  hostname?: string;
  vertical?: string;
  aidaptive_customer_status?: string;
  processed_on_legacy_platform?: boolean;
  google_account?: any;
  onboarding_notification_sent?: boolean;
  product_settings?: any[];
  url_prefixes?: any[];
  internal_settings?: {
    image_modeling_enabled: boolean;
    billing_strategy?: string;
    customer_size?: string;
    icp?: boolean;
    paying_customer?: boolean;
    sla_tier?: string;
    entity_enhancer_enabled?: boolean;
    merchandising_enabled?: boolean;
    recs_enabled?: boolean;
    search_enabled?: boolean;
    modeling_learning_period_start_time?: string;
    modeling_learning_period_end_time?: string;
    modeling_time_window_days?: number;
    modeling_time_window_transaction_count?: number;
    modeling_optimization_parameter?: string;
    modeling_namespace_breakdown?: any[];
    modeling_current_status?: string;
    sub_vertical?: string;
    aidaptive_product_launch_dates?: any[];
  };
}

export function isShopifyCustomer(customer: AidaptiveCustomerSetting): boolean {
  return customer.cid?.startsWith('shopify-');
}

export const SHOPIFY_STORE_SUFFIX = '.myshopify.com';

export function getShopifyName(customer: AidaptiveCustomerSetting, cleanDomain?: string) {
  if (!cleanDomain) {
    cleanDomain = getCleanDomain(customer);
  }
  const shopifyName = cleanDomain.endsWith(SHOPIFY_STORE_SUFFIX)
    ? cleanDomain.replace(SHOPIFY_STORE_SUFFIX, '')
    : '';
  return shopifyName;
}

export function getCleanDomain(customer: AidaptiveCustomerSetting) {
  if (!customer.hostname) return ''; // though it should always be defined

  // NB: we cannot do new URL(customer.hostname) because hostname is sometimes already "cleaned", like "twiddy.com"
  let domain = customer.hostname;

  domain = removePrefix(domain, 'https:');
  domain = removePrefix(domain, '//');
  domain = removePrefix(domain, '/'); // in case of typo - missing '/'; e.g. "https:/mysite.com"
  domain = removePrefix(domain, 'www.');

  if (domain.endsWith('/')) {
    domain = domain.substring(0, domain.length - 1);
  }
  return domain;
}

function removePrefix(s: string, prefix: string) {
  if (s.startsWith(prefix)) {
    return s.substring(prefix.length);
  } else {
    return s;
  }
}
