import { Component, Input, OnInit } from '@angular/core';
import { RecoData, getRecoImage, getRecoPrice } from 'aidaptive-sdk';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {

  @Input() product: RecoData;
  public showMore  = false;
  starsArray: number[];
  price: string;
  imageUrl: string;
  
  constructor() {
    this.starsArray = Array(5).fill(0).map((_, index) => index + 1);
  }

  ngOnInit(): void {
    const amount = getRecoPrice(this.product.price);
    this.price = amount
      ? `$${amount}` // TODO get currency symbol
      : '';

    this.imageUrl = getRecoImage(this.product);
  }

  onClickShowMore(){
    this.showMore = !this.showMore;
  }

}
