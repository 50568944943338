import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';

import { AnalyticsService } from './analytics.service';
import { UserService } from 'src/app/services/user.service';
import { MsgboxService } from './msgbox.service';
import { Observable, Subscriber } from 'rxjs';
import { justWait } from '../util/helpers';

type PopStateMethod = (params: { [key: string]: string }) => void;

@Injectable({
  providedIn: 'root'
})
export class DeeplinkService {

  private urlBeforeLogin = '';
  private cid = '';
  private popstateMethods: { [key: string]: PopStateMethod } = {};
  private cidSubscribers: Subscriber<string>[] = [];

  private saveShouldReuseRoute: any;
  private saveOnSameUrlNavigation: 'reload'|'ignore';


  constructor(
    private userService: UserService,
    private clipboard: Clipboard,
    private router: Router,
    private msgboxService: MsgboxService,
    private analytics: AnalyticsService,
  ) {
    this.saveShouldReuseRoute = this.router.routeReuseStrategy.shouldReuseRoute;
    this.saveOnSameUrlNavigation = this.router.onSameUrlNavigation; // normaly == "ignore" 

    window.onpopstate = (event: PopStateEvent) => this.onpopstate(event);
  }

  copyDeepLinkFromUrl() {
    this.copyDeepLinkToItem(null);
  }

  async copyDeepLinkToItem(itemId?: string) {
    const { params, prefix } = parsePath(getCurrentUrl());
    params.itemId = itemId;
    params.c = this.cid || (await this.userService.getUser()).cid;
    const newUrl = buildPath(prefix, params);

    await this.copyDeepLink(newUrl);
  }

  private async copyDeepLink(deepUrl: string) {
    const deeplink = `${location.origin}${deepUrl}`;

    const copied = this.clipboard.copy(deeplink);

    await this.msgboxService.showMsg(`Deep link:\n${deeplink}${copied ? '\n(copied to your clipboard)' : ''}`);
  }

  navigateBeforeLogin(url: string) {
    this.urlBeforeLogin = url;
    this.router.navigateByUrl('/login/link');
  }

  navigateAfterLogin() {
    const { params } = parsePath(this.urlBeforeLogin);
    if (params.c) {
      this.setDynamicCid(params.c);
    }
    this.navigateToUrl(this.urlBeforeLogin);
  }

  clearSearchParams(): void {
    const url = new URL(location.href);
    url.search = '';
    history.replaceState({}, '', url.href);
  }

  private setDynamicCid(cid: string) {
    if (cid === this.cid) return;
    this.cid = cid;
    const originalCid = this.userService.setSuperuserCid(cid);

    // Pass current CID to anyone listening
    const currentCid = cid || originalCid;
    this.cidSubscribers.forEach(subscriber => subscriber.next(currentCid));
  }

  public observeDynamicCid(): Observable<string> {
    return new Observable((subscriber) => {
      this.cidSubscribers.push(subscriber);
      return () => {
        const i = this.cidSubscribers.indexOf(subscriber);
        this.cidSubscribers.splice(i, 1);
      };
    });
  }

  /**
   * Call this in the constructor of your component IF your component is at the top of a route (URL "path").
   * This will handle useful things for you, including some analytics logging and "dynamic CID" feature for 
   * Aidaptive people to test client pages.
   *
   * @param prefix - e.g. "/insights"
   * @returns parameters from the current URL, default one is "itemId"
   */
  parseRoute(prefix: string): {[key: string]: any} {
    this.analytics.logEvent('screen_view', { screen_name: prefix });

    const { params } = parsePath(this.router.url, prefix);

    // If we had the "c" parameter, this is CID
    this.setDynamicCid(params.c || '');

    return params;
  }

  private async navigateForceReload(url: string) {
    this.forceRouterReload(async () => {
      await this.navigateToUrl(url);
    });
  }

  private async forceRouterReload(action: ()=>Promise<void>) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';

    await action();

    this.router.routeReuseStrategy.shouldReuseRoute = this.saveShouldReuseRoute;
    this.router.onSameUrlNavigation = this.saveOnSameUrlNavigation;
  }

  async navigateToUrl(url: string): Promise<boolean> {
    if (url === '#') {
      return true;
    }
    const { params, prefix } = parsePath(url);

    // CID can be set in URL (that is rare). Otherwise we keep currently forced CID if any
    params.c = params.c || this.cid; // NB: another way to switch CID is to call switchCid

    const newUrl = buildPath(prefix, params);

    if (url.indexOf('#') >= 0) {
      // Force-reload when requested URL has a hash (done for tab-navigation; maybe non-optimal)
      await this.forceRouterReload(async () => {
        await this.router.navigateByUrl(newUrl);
      });
      return true;
    } else {
      return await this.router.navigateByUrl(newUrl);
    }
  }

  public switchCid(cid: string, url: string = null) {
    this.setDynamicCid(cid);

    let newUrl = url || getCurrentUrl();
    // Remove #c#cid from URL if there
    if (newUrl.indexOf('#c#') > -1) {
      const { params } = parsePath(newUrl);
      newUrl = newUrl.replace(`#c#${params.c}`, '');
    }

    // chosing to reload all makes our life easier for now
    this.navigateForceReload(newUrl);
  }

  // Commented out since "Back" inside a page items is not recommended for user expectations are not met
  // See for example https://baymard.com/blog/back-button-expectations
  // public navigateToItem(itemId: string) {
  //   const { params, prefix } = parsePath(getCurrentUrl());
  //   params.itemId = itemId;
  //   const newUrl = buildPath(prefix, params);
  //   history.pushState({}, '', newUrl);
  // }

  public pushSearchParams(updatedParams: { [key: string]: string }) {
    const url = new URL(location.href);
    for (const [key, value] of Object.entries(updatedParams)) {
      url.searchParams.set(key, value);
    }
    history.pushState({}, '', url.href);
  }

  public setUrlHashParams(updatedParams: { [key: string]: string }) {
    const url = new URL(location.href);
    const parsedHashParams = parseHashParams(url.hash);

    Object.assign(parsedHashParams, updatedParams);

    let newHash = '';
    for (const [key, value] of Object.entries(parsedHashParams)) {
      if (key === 'itemId') continue;
      newHash += `#${key}#${value}`;
    }
    if (parsedHashParams.itemId) newHash += `#${parsedHashParams.itemId}`;

    url.hash = newHash;
    history.replaceState({}, '', url.href);
  }

  public removeUrlHashParams(paramsToRemove: string[]) {
    const url = new URL(location.href);
    const parsedHashParams = parseHashParams(url.hash);

    for (const param of paramsToRemove) {
      delete parsedHashParams[param];
    }

    let newHash = '';
    for (const [key, value] of Object.entries(parsedHashParams)) {
      if (key === 'itemId') continue;
      newHash += `#${key}#${value}`;
    }
    if (parsedHashParams.itemId) newHash += `#${parsedHashParams.itemId}`;

    url.hash = newHash;
    history.replaceState({}, '', url.href);
  }
 

  public onPopState(prefix: string, fn: PopStateMethod) {
    this.popstateMethods[prefix] = fn;
  }

  public unregisterPopState(prefix: string) {
    delete this.popstateMethods[prefix];
  }

  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  private onpopstate(event: PopStateEvent) {
    const url = location.pathname + location.search + location.hash;
    const { params, prefix } = parsePath(url);
    const newCid = params.c || '';

    if (newCid !== this.cid) {
      this.setDynamicCid(newCid);
      this.forceRouterReload(async () => {
        await justWait(500);
      });
    } else {
      for (const [key, fn] of Object.entries(this.popstateMethods)) {
        if (key === prefix || key.charAt(0) === '*') {
          fn(params);
        }
      }
    }
  }
}



function getCurrentUrl(): string {
  return location.pathname + location.hash;
}

// Returns prefix, e.g. '/insights'
function parsePrefix(fullUrl: string): string {
  const paths = fullUrl.split('/');
  const last = paths.length - 1;
  let endPos = paths[last].indexOf('?');
  if (endPos === -1) {
    endPos = paths[last].indexOf('#');
  }
  if (endPos >= 0) {
    const lastPath = paths[last].substring(0, endPos);
    return `${paths.slice(0, last).join('/')}/${lastPath}`; 
  } else {
    return fullUrl;
  }
}

interface ParseResult {
  prefix: string;
  params: any;
}

function parsePath(fullUrl: string, prefix?: string): ParseResult {
  if (!prefix) {
    prefix = parsePrefix(fullUrl);
  } else if (!fullUrl.startsWith(prefix)) {
    console.error(`parseRoute(${prefix}) VS ${fullUrl}`);
    return null;
  }

  const parsedParams: any = {};
  const suffix = fullUrl.substring(prefix.length);
  let paramStr;
  switch (suffix.charAt(0)) {
    case '#': // "#itemId" or "#c#A001#itemId"
      paramStr = suffix;
      break;
    case '': // no params after prefix, all is fine too
      break;
    case '?':
      const sharp = suffix.indexOf('#');
      let queryParams;
      if (sharp >= 0) {
        paramStr = suffix.substring(sharp);
        queryParams = new URLSearchParams(suffix.substring(0, sharp));
      } else {
        queryParams = new URLSearchParams(suffix);
      }
      for (const key of queryParams.keys()) {
        parsedParams[key] = queryParams.get(key);
      }
      break;
    default: 
      console.error(`parseRoute(${prefix}) VS ${fullUrl}`);
      return null;
  }

  if (paramStr) {
    const parsedHashParams = parseHashParams(paramStr);
    Object.assign(parsedParams, parsedHashParams);
  }

  return { params: parsedParams, prefix: prefix };
}

export function parseHashParams(hash: string): { [key: string]: string } {
  const params: { [key: string]: string } = {};
  const items = hash.substring(1).split('#');
  const last = items.length - 1;
  for (let i = 0; i < last; i += 2) {
    params[items[i]] = items[i + 1];
  }
  // Optional last (unnamed) parameter is "itemId"
  if (last % 2 === 0) {
    params.itemId = items[last];
  }
  return params;
}

function buildPath(prefix: string, params: any): string {
  let paramStr = '';
  let itemIdValue = null;

  for (const key in params) {
    const value = params[key];
    if (!value && value !== 0) continue; // undefined or empty string are ignored (but 0 is valid)

    if (key === 'itemId') {
      itemIdValue = value; // anonymous "itemId" always added last
    } else {
      paramStr += `#${key}#${value}`;
    }
  }
  if (itemIdValue !== null) {
    paramStr += `#${itemIdValue}`;
  }
  return prefix + paramStr;
}
