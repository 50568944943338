import { Injectable } from '@angular/core';
import { UserEntity } from '../models/user.model';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BackendApiService } from './backend-api.service';
import { environment } from 'src/environments/environment';
import { AidaptiveCustomerSettingService } from './aidaptive-customer-setting.service';
import { AidaptiveCustomerSetting } from '../models/aidaptive-customer-settings.model';

@Injectable({
  providedIn: 'root'
})

export class MetricsDashboardService {
  private minerUrl = environment.minerURL;
  constructor(
    private firestore: AngularFirestore,
    private backendApi: BackendApiService,
    private aidaptiveCustomerSettings: AidaptiveCustomerSettingService
  ) { }

  public async downloadRawData(cid: string, startDate: string, endDate: string) {
    return await this.backendApi.get(`/metrics/raw_data?cid=${cid}&start_date=${startDate}&end_date=${endDate}`, this.minerUrl);
  }

  public async downloadTransactionRawData(cid: string, startDate: string, endDate: string) {
    return await this.backendApi.get(`/metrics/transactions_data?cid=${cid}&start_date=${startDate}&end_date=${endDate}`, this.minerUrl);
  }

  public async merticsFilterInfo(cid: string, service: string) {
    return await this.backendApi.get(`/metrics/filter_info?cid=${cid}&service=${service}`, this.minerUrl);
  }

  getPageTypes(pageTypesArray) {
    const pageTypeValues = (pageTypesArray || []).map(pageType => {
      return {
        value: pageType,
        viewValue: pageType.charAt(0).toUpperCase() + pageType.slice(1).replace(/-/g, ' ')
      };
    });
    pageTypeValues.unshift({ value: "all", viewValue: "All" });
    return pageTypeValues;
  }

  getMetricTypes(_customer: AidaptiveCustomerSetting) {
    const metricTypes = [
      { value: 'rpv', viewValue: 'RPV', endpointName: 'rpv' },
      { value: 'aov', viewValue: 'AOV', endpointName: 'aov' },
      { value: 'total_revenue', viewValue: 'Total Revenue', endpointName: 'event' }
    ];
    return metricTypes;
  }

  getRecomsEventTypes(_customer: AidaptiveCustomerSetting) {
    const eventTypes = [
      { value: 'all', viewValue: 'All' },
      { value: 'impression', viewValue: 'Impression' },
      { value: 'click', viewValue: 'Click' }
    ];
    return eventTypes;
  }

  getSearchEventTypes(_customer: AidaptiveCustomerSetting) {
    const eventTypes = [
      { value: 'all', viewValue: 'All' }
    ];
    return eventTypes;
  }

  getProductTypes(customer: AidaptiveCustomerSetting) {
    const productTypes = [
      { value: 'recommendations', viewValue: 'Recommendations' },
      // TODO: Enable once backend adds support
      { value: 'search', viewValue: 'Search' } // we already have search in pagetype so using a different value here
      // {value: 'marketing', viewValue: 'Marketing'}
    ];
    if (customer.internal_settings.merchandising_enabled) {
      productTypes.push({ value: "merchandising", viewValue: "Collections" });
    }
    return productTypes;
  }

  async saveUserFavoritedGraph(user: UserEntity, graphConfig: any): Promise<void> {
    // Firestore requires reference to have even number of collections i.e 4
    const doc = this.firestore.doc(`/UserDashboard/${user.id}/${user.cid}/favorites`);
    const configData = {};
    configData[graphConfig.id] = {
      favorited: true,
      pinnedConfig: graphConfig.pinnedConfig
    };
    return doc.set(configData, { merge: true });
  }

  async getUserFavoritedGraphs(user: UserEntity) {
    const doc = this.firestore.doc<any>(`/UserDashboard/${user.id}/${user.cid}/favorites`);
    const snapshot = await doc.get().toPromise();
    return { ...snapshot.data() };
  }
}
