<a class="block rounded-lg p-4 border-2 shadow-lg shadow-indigo-100">
    <img alt="Home" [src]="propertyImage" class="h-56 w-full rounded-md object-cover" />
    <div class="mt-2">
        <div class="mt-1">
            <div *ngIf="property.price_text_small">
                <dd class="text-sm text-gray-500">{{ property.price_text_small }}</dd>
            </div>
            <div>
                <dd class="font-semibold mt-1">{{ property.title||property.name }}</dd>
            </div>
        </div>
        <div *ngIf="property.city" class="mt-2 flex items-center gap-4 text-xs justify-evenly">
            <div class="sm:inline-flex sm:shrink-0 sm:items-center sm:gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-6 h-6 text-indigo-700">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                </svg>

                <div class="mt-1.5 sm:mt-0">
                    <p class="text-gray-500">City</p>
                    <p class="font-medium">{{property.city}}</p>
                </div>
            </div>

            <div *ngIf="bathrooms" class="sm:inline-flex sm:shrink-0 sm:items-center sm:gap-2">
                <svg class="h-4 w-4 text-indigo-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                </svg>

                <div class="mt-1.5 sm:mt-0">
                    <p class="text-gray-500">Bathrooms</p>
                    <p class="font-medium">{{ bathrooms }}</p>
                </div>
            </div>

            <div *ngIf="bedrooms" class="sm:inline-flex sm:shrink-0 sm:items-center sm:gap-2">
                <svg class="h-4 w-4 text-indigo-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
                </svg>

                <div class="mt-1.5 sm:mt-0">
                    <p class="text-gray-500">Bedrooms</p>
                    <p class="font-medium">{{ bedrooms }}</p>
                </div>
            </div>
        </div>
        <a href={{property.landing_page_url}} target="_blank"
            class="w-full rounded-md mt-4 mx-2 cursor-pointer inline-block text-sm font-medium  text-white">
            <span class="block border rounded-md border-current bg-blue-600 px-8 py-2 font-semibold">
                Property Details
            </span>
        </a>
    </div>
</a>