import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-hospitality-listing-card',
  templateUrl: './hospitality-listing-card.component.html',
  styleUrls: ['./hospitality-listing-card.component.scss']
})
export class HospitalityListingCardComponent implements OnInit {
  @Input() item;
  @Input() selectedEntitiesId;  
  @Output() selectedEntitiesIdChange = new EventEmitter();
  @Input() selectedEntityDisplay?;  
  entityImage;

  constructor() { }

  ngOnInit() {
    this.entityImage = this.item?.featured_image?.url || this.item?.listing_images?.[0]?.url;
  }

  onCheckboxChange(itemId: string, isChecked: boolean) {
    const checkboxState =  {
      itemId,
      isChecked
    };
    this.selectedEntitiesIdChange.emit(checkboxState);
  }

  isListingChecked (item) {
    const id = item?.id || item?.listing_id;
    return this.selectedEntitiesId.includes(id);
  }
}
