import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { PageService } from 'src/app/services/page.service';


type FilterType = 'dateRange' | 'pageType' | 'numberOfDays' | 'customerType' | 'analyticsVersion';
type DropdownValues = { value: string, viewValue: string }[];


@Component({
  selector: 'filter-selector',
  templateUrl: './filter-selector.component.html',
  styleUrls: ['./filter-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterSelectorComponent implements OnInit {

  @Input() filterType: FilterType;
  @Input() values: DropdownValues;
  @Input() initValue: any; // if not given, first value is selected by default
  @Input() startDateRange: Date;
  @Input() endDateRange: Date;
  @Input() backendStartDate: Date;

  @Output() changeEvent = new EventEmitter<any>();

  dateRangeForm: UntypedFormGroup;
  pageTypeForm: UntypedFormGroup;
  daysSelectionForm: UntypedFormGroup;
  customerTypeForm: UntypedFormGroup;
  analyticsVersionForm: UntypedFormGroup;

  dateRangeValues: DropdownValues = [
    { value: 'custom', viewValue: 'Custom' },
    { value: 'last7Days', viewValue: 'Last 7 Days' },
    { value: 'last14Days', viewValue: 'Last 14 Days' },
    { value: 'last30Days', viewValue: 'Last 30 Days' },
    { value: 'last90Days', viewValue: 'Last 90 Days' },
    { value: 'lastMonth', viewValue: 'Last Month' },
    { value: 'last3Months', viewValue: 'Last 3 Months' },
  ];


  constructor(
    public pageService: PageService,
    private formBuilder: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {
    switch (this.filterType) {
      case 'dateRange':
        this.dateRangeForm = this.formBuilder.group({ dateRange: [this.initValue] });
        break;
      case 'numberOfDays':
        this.daysSelectionForm = this.formBuilder.group({ numberOfDays: [this.initValue] });
        break;
      case 'pageType':
        this.pageTypeForm = this.formBuilder.group({ pageType: [this.initValue] });
        break;
      case 'customerType':
        this.customerTypeForm = this.formBuilder.group({ customerType: [this.initValue] });
        break;
      case 'analyticsVersion':
        this.analyticsVersionForm = this.formBuilder.group({ analyticsVersion: [this.initValue] });
        break;
      default:
        console.error('invalid filterType', this.filterType);
    }
  }

  dateRangeChanged(event: any) {
    const currentDate = new Date();
    let startDate, endDate;


    switch (event.value) {
      case 'last7Days':
        startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 7);
        endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1);
        break;

      case 'last14Days':
        startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 14);
        endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1);
        break;

      case 'last30Days':
        startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 30);
        endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1);
        break;

      case 'last90Days':
        startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 90);
        endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1);
        break;

      case 'lastMonth':
        startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
        endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
        break;

      case 'last3Months':
        startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 3, 1);
        endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
        break;

      case 'custom':
        startDate = this.backendStartDate;
        endDate = new Date();
        break;

      default:
        return;  
    }

    const dateFromBackend = this.backendStartDate;
    const dateSelectedByUser = new Date(startDate);
    const availableStartDate = (dateFromBackend > dateSelectedByUser) ? dateFromBackend : dateSelectedByUser;
    this.pageService.updateFilter({ startDate: availableStartDate, endDate: endDate });
    this.changeEvent.emit({ startDate: availableStartDate, endDate: endDate });
  }


  startDateChanged(event: any) {
    this.pageService.updateFilter({ startDate: event.target.value });
    this.changeEvent.emit({ startDate: event.target.value });
  }

  endDateChanged(event: any) {
    this.pageService.updateFilter({ endDate: event.target.value });
    this.changeEvent.emit({ endDate: event.target.value });
  }

  pageTypeChanged(event: any) {
    this.pageService.updateFilter({ pageType: event.value });
    this.changeEvent.emit({ pageType: event.value });
  }

  numberOfDaysChanged(event: any) {
    this.pageService.updateFilter({ days: event.value });
    this.changeEvent.emit({ numberOfDays: event.value });
  }

  customerTypeChanged(event: any) {
    this.pageService.updateFilter({ customerType: event.value });
    this.changeEvent.emit({ customerType: event.value });
  }

  analyticsVersionChanged(event: any) {
    this.pageService.updateFilter({ analyticsVersion: event.value });
    this.changeEvent.emit({ analyticsVersion: event.value });
  }
}
