import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { MyGraphConfig } from '../mygraph/mygraph.component';
import { BackendDataService } from 'src/app/services/backend-data.service';
import { InputParams } from '../generic-graph/generic-graph.component';
import { sortByMonth } from 'src/app/util/helpers';

Chart.register(...registerables);


@Component({
  selector: 'app-jarvis-chart',
  templateUrl: './jarvis-chart.component.html',
  styleUrls: ['./jarvis-chart.component.scss']
})
export class JarvisChartComponent implements OnInit {

  public chart: any;
  public rawData: any[];
  public footerText: string;
  public loading: boolean;
  public data: any[]; // data for plotly graph
  public layout: any; // layout for plotly graph
  public plotlyConfig: any;
  public renderedBreakpoints: any; //graph size based on screen size


  showFilters = false;
  filterConfig;
  errorFetchingData = false;

  public filterCollection: any;

  isInitialized = false;

  @Input() config;
  @Input() parent;
  @Input() inputParams: InputParams;
  @Input() size: string;


  constructor(
    private elementRef: ElementRef,
    private backendDataService: BackendDataService,
  ) { }

  ngOnInit() {
    this.errorFetchingData = false;
    this.fetchStatData(this.config);
  }

  private async fetchStatData(config): Promise<void> {
    this.loading = true;
    try {
      const graphUrl = config.url;

      // TODO: pass inputParams thru instead of filterConfig
      const filterConfig = {};

      this.filterConfig = filterConfig;
      // Some graph urls need customization before a request is made

      if (graphUrl) {
        this.rawData = await this.backendDataService.fetchJsonData(graphUrl);
      } else {
        console.error(`Empty url for ${config.title}`);
        this.rawData = [];
      }
      let filteredData: any[];
      filteredData = this.rawData;

      if (config.responseHandler) {
        const responseData = config.responseHandler(filteredData, this);
        if (responseData && responseData.length) {
          filteredData = responseData;
        }
      }
      if (filteredData.length > 0) {
        this.renderGraph(filteredData, config);
      }
      this.errorFetchingData = false;

    } catch (error) {
      console.log(error);
      this.errorFetchingData = true;
    }
    this.loading = false;
  }

  public formatLabel(label: string, forTemplate = false) {
    const labelArr = label.split('_');
    const capitalizedLabelArr = labelArr.map(el => el.charAt(0).toUpperCase() + el.slice(1));
    return forTemplate ? capitalizedLabelArr.join(' ') : capitalizedLabelArr.join('');
  }

  extractYFields(data: any[]): number[] {
    const yFields: number[] = [];

    for (const item of data) {
      yFields.push(item.y);
    }

    return yFields;
  }

  findColorFromConfig(label, obj2) {
    for (const obj of obj2) {
      if (obj.name === label) {
        return obj.color;
      }
    }
    return null; 
  }
  
  transformDataForBarChart(data) {
    return data.map(item => {
      return {
        label: item.name,
        data: item.y,
        backgroundColor: this.findColorFromConfig(item.name,this.config.multicurve),
      };
    });
  }

  transformDataForLineChart(data) {
    return data.map(item => {
      return {
        label: item.name,
        data: item.y,
        borderColor: this.findColorFromConfig(item.name,this.config.multicurve),
        backgroundColor: this.findColorFromConfig(item.name,this.config.multicurve),
        fill: false,
        tension: 0.5
      };
    });
  }


  sortDataByDate(data) {
    return data.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      if (dateA < dateB) {
        return -1;
      }
      if (dateA > dateB) {
        return 1;
      }
      return 0;
    });
  }


  renderGraph(filteredData, config) {
    const cfg = config;
    const sortedData = this.sortDataByDate(filteredData);
    this.data = this.buildChartData(sortByMonth(sortedData), cfg);
    const label = this.data;
    if (this.data[0].type === "bar") {
      this.data = this.transformDataForBarChart(this.data);
    } else if (this.data[0]?.type === "line") {
      this.data = this.transformDataForLineChart(this.data);
    }
    this.createChart(config.graphType, label, this.data, config.id, config.yTitle);
  }

  private buildChartData(filteredData, config) {
    const cfg = config; // shortcut
    const plotlyDataArray = [];

    if (cfg.multicurveBy) {
      this.buildMulticurveByData(cfg, filteredData, plotlyDataArray);
    } else if (cfg.multicurve) {
      cfg.multicurve.forEach(curveCfg => {
        this.buildOneChartData(cfg, filteredData, curveCfg, plotlyDataArray);
      });
    } else {
      this.buildOneChartData(cfg, filteredData, null, plotlyDataArray);
    }
    return plotlyDataArray;
  }

  private buildOneChartData(cfg: MyGraphConfig, filteredData: any[], curveCfg, dataArray: any[]) {
    const graphType = (curveCfg && curveCfg.graphType) || cfg.graphType;
    if (graphType === 'area') {
      this.buildAreaCurveData(filteredData, cfg, curveCfg, dataArray);
      return;
    }

    const rows = filteredData;
    const xName = (curveCfg && curveCfg.x) || cfg.x;
    const yName = (curveCfg && curveCfg.y) || cfg.y;
    const columnType = curveCfg?.columnType;
    const filter = (curveCfg && curveCfg.filter) || cfg.filter;
    const renderXFunc = (curveCfg && curveCfg.renderXFunc) || cfg.renderXFunc;
    const renderYFunc = (curveCfg && curveCfg.renderYFunc) || cfg.renderYFunc;
    const hoverFunc = (curveCfg && curveCfg.hoverFunc) || cfg.hoverFunc;
    const name = (curveCfg && curveCfg.name) || undefined;

    const markerName = cfg.marker;
    const markerData = {
      x: [],
      y: [],
      name: cfg.markerLabel,
      type: 'scatter',
      mode: 'markers',
      marker: {
        color: 'rgb(255,0,0)',
        size: 14,
        line: {
          color: 'rgb(0,0,0)',
          width: 2
        }
      },
      showlegend: true,
      legend: { orientation: 'h' }
    };

    if (rows.length && columnType !== 'computed') {
      const row0 = rows[0];
      if (row0[xName] === undefined) {
        console.error(`No colum ${xName} in ${cfg.title} (${Object.keys(row0)})`);
      }
      if (row0[yName] === undefined) {
        console.error(`No colum ${yName} in ${cfg.title} (${Object.keys(row0)})`);
      }
    }

    const xs = [], ys = [], hovervals = [];
    for (let n = 0; n < rows.length; n++) {
      const row = rows[n];
      // Always filter out null or undefined Y values
      if (!row[yName] && row[yName] !== 0) {
        continue;
      }
      // NB: filter may modify row
      if (filter && filter(row, cfg) === false) {
        continue;
      }

      if (renderXFunc) {
        xs.push(renderXFunc(row));
      } else {
        xs.push(row[xName]);
      }

      if (renderYFunc) {
        ys.push(renderYFunc(row));
      } else {
        ys.push(row[yName]);
      }

      if (hoverFunc) {
        hovervals.push(hoverFunc(row));
      }

      if (markerName && row[markerName]) {
        const i = xs.length - 1;
        markerData.x.push(xs[i]);
        markerData.y.push(ys[i]);
      }
    }
    if (xs.length === 0) {
      return; // no data for this curve
    }

    const result: any = { x: xs, y: ys };

    if (graphType === 'stack') {
      result.type = 'bar';
    } else if (graphType === 'lineWithFill') {
      result.type = 'line';
      result.fill = 'tozeroy';
    } else {
      result.type = graphType || 'bar';
    }

    if (name) {
      result.name = name;
    } else {
      result.name = ''; // otherwise "trace 0" shows next to regular values when we have markerData
      result.showlegend = false;
    }

    if (hovervals.length) {
      result.text = hovervals;
      result.hovertemplate = '%{text}';
    }

    dataArray.push(result);

    if (markerName && markerData.x.length > 0) {
      dataArray.push(markerData);
    }
    if (cfg.multicurve && (dataArray.length === cfg.multicurve.length)) {
      for (let i = 0; i < cfg.multicurve.length; i++) {
        if (cfg.multicurve[i].lineColor) {
          // Set custom lineColor for each line
          dataArray[i].line = {
            color: cfg.multicurve[i].lineColor,
            width: 2
          };
        }
      }
    }
  }

  buildAreaCurveData(rows: any[], cfg: MyGraphConfig, curveCfg, dataArray: any[]) {
    const xName = curveCfg.x;
    const lowName = curveCfg.yLow;
    const highName = curveCfg.yHigh;
    const filter = curveCfg.filter;

    let levelName;
    let level = 0;
    if (curveCfg.name) {
      const varMatch = /{[a-zA-Z_-]+}/.exec(curveCfg.name);
      if (varMatch) {
        levelName = varMatch[0].slice(1, -1); // e.g. "confidence_level"
      }
    }

    const xsHigh = [], ysHigh = [], xsLow = [], ysLow = [];

    for (let n = 0; n < rows.length; n++) {
      const row = rows[n];
      const low = row[lowName];
      if (!low && low != 0) continue;
      const high = row[highName];
      if (!high && high != 0) continue;
      if (filter && filter(row, cfg) === false) {
        continue;
      }
      level = row[levelName];
      const x = row[xName];
      xsHigh.push(x);
      ysHigh.push(high);
      xsLow.push(x);
      ysLow.push(low);
    }

    const label = Number(level)
      ? curveCfg.name.replace(`{${levelName}}`, (level * 100).toFixed(1))
      : '';
    dataArray.push({
      x: xsHigh.concat(xsLow.reverse()),
      y: ysHigh.concat(ysLow.reverse()),
      fill: "toself",
      fillcolor: curveCfg.color || "rgba(0,100,80,0.2)",
      line: { color: "transparent" },
      name: label,
      showlegend: label !== '',
      type: "scatter",
      legend: { orientation: 'h' }
    });
  }

  buildMulticurveByData(cfg: MyGraphConfig, filteredData: any[], dataArray: any[]) {
    const rows = filteredData;
    const xName = cfg.x;
    const yName = cfg.y;

    if (rows.length && rows[0][cfg.multicurveBy] === undefined) {
      console.error(`No colum "${cfg.multicurveBy}" in ${cfg.title} (${Object.keys(rows[0])})`);
    }
    const curveMap = {};
    for (let n = 0; n < rows.length; n++) {
      const row = rows[n];
      if (cfg.filter && cfg.filter(row, cfg) === false) {
        continue;
      }
      const curveName = row[cfg.multicurveBy];
      let curveXYs = curveMap[curveName];
      if (!curveXYs) curveXYs = curveMap[curveName] = [[], [], []];

      // Check if the graph has custom render function for x & y
      if (cfg.renderXFunc) {
        const renderXVal = cfg.renderXFunc(row);
        curveXYs[0].push(renderXVal);
      } else {
        curveXYs[0].push(row[xName]);
      }

      if (cfg.renderYFunc) {
        const renderYVal = cfg.renderYFunc(row);
        curveXYs[1].push(renderYVal);
      } else {
        curveXYs[1].push(row[yName]);
      }

      // Check if the graph has a custom hover function & values
      if (cfg.hoverFunc) {
        const hoverVal = cfg.hoverFunc(row);
        curveXYs[2].push(hoverVal);
      }
    }
    Object.entries(curveMap).forEach(([curveName, xys]) => {
      const result: any = {
        type: 'line', x: xys[0], y: xys[1], name: curveName
      };

      if (xys[2] && xys[2].length) {
        // Check if hover vals are present
        // Set custom hover template
        result.text = xys[2];
        result.hovertemplate = '%{text}';
      }
      dataArray.push(result);
    });
  }

  createChart(chartType, label, data, chartId, yaxisLabel) {
    const chartRef = this.elementRef.nativeElement.querySelector("#" + chartId);
    this.chart = new Chart(chartRef, {
      type: chartType,
      data: {
        labels: label[0].x,
        datasets: data
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: yaxisLabel,
              font: {
                weight: 'bold',
                size: "15px"
              },

            }
          },
        }
      }

    });



  }
}